<template>
    <button v-on:click="onClick" class="c-DiscoverBtn u-font-bold t-btn--secondary" type="button">
        {{ text }}

        <span>
          <span />
          <svg viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4H5V0H4V4H0V5H4V9H5V5H9V4Z" fill="#fff"/>
          </svg>
        </span>
    </button>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'DiscoverBtn',

        props: {
            'text': { type: String, value: '' },
            'number': { type: Number, value: -1 },
        },

        computed: {
            ...mapState('main', [
                'activeContentOverlay',
                'contentOverlayOpen',
            ]),
        },

        methods: {
            onClick() {
                this.setActiveContentOverlay(this.number);
                this.toggleContentOverlayOpen(!this.contentOverlayOpen)
            },

            ...mapActions('main', [
                'setActiveContentOverlay',
                'toggleContentOverlayOpen',
            ]),
        }
    }
</script>

<style lang="stylus">
  .c-DiscoverBtn {
    margin-top 35px

    @media $breakpoints.sm {
        margin-top 32.5px
    }
  }
</style>