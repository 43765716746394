<template>
    <div class="c-SectionOne u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady, 'is-from-sections': isFromSections, 'is-from-section': isFromSection }">
        <sub-header v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="c-SectionOne-blur" v-bind:active="isPageReady" v-bind:number="1" v-bind:title="'The Future of innovation'"></sub-header>

        <section id="test" class="js-section js-ui-switch js-nav-section c-SectionOne-intro u-relative u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="c-Container u-absolute u-pos-tl u-fit u-flex u-align-items-c | u-align-items-fe@sm">
                <object-fit v-bind:width="1280" v-bind:height="720" v-bind:width-mobile="374" v-bind:height-mobile="664" class="c-SectionOne-intro-video u-bg--blue u-fixed u-pos-tl u-fit">
                    <video-observer class="u-absolute u-pos-tl u-fit">
                        <video-responsive>
                            <video class="js-object-fit js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/one/poster-intro-mobile.jpg` : `${publicPath}static/images/one/poster-intro.jpg`" autoplay loop muted playsinline>
                                <source :src="`${publicPath}static/videos/section1.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/section1.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/section1-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/section1-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </object-fit>

                <div class="u-relative u-w10of16 u-offset-l-w4of16 u-force-3d | u-w12of16@lg u-offset-l-w3of16@lg | u-w14of16@md u-offset-l-w2of16@md | u-fit-w@sm u-offset-l-w0of16@sm">
                    <div class="c-SectionOne-intro-title t-h1">
                        <span class="c-SectionOne-line c-SectionOne-line--0 c-SectionOne-intro-title-line-1 u-block u-offset-l-w2of10 | u-offset-l-w1of8@sm">The Future</span>

                        <div class="u-fit-w u-flex u-flex-flow-row-wrap u-marg-t-xl |  u-marg-t-0@sm">
                            <span class="c-SectionOne-line c-SectionOne-line--1 c-SectionOne-intro-title-line-2 u-block">of innovation</span>

                            <div class="c-SectionOne-intro-desc u-w4of10 u-pad-l-lg | u-w6of8@sm u-offset-l-w1of8@sm u-pad-l-0@sm">
                                <div class="c-SectionOne-line c-SectionOne-line--3">
                                    <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                        <svg class="c-SectionOne-intro-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                            <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                            <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                            <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                        </svg>
                                        <span>section 01</span>
                                    </span>
                                </div>

                                <p class="c-SectionOne-line c-SectionOne-line--4 c-SectionOne-intro-text t-text--sm u-text-left">Innovation remains a strong priority in the wake of COVID-19 but with clear concerns about the future of innovation progress.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-SectionOne-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-SectionOne-intro-scroll-btn c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && activeContentOverlay === 1 && !isFF, 'is-hidden': contentOverlayOpen && activeContentOverlay === 1 && isFF }" class="js-section js-ui-switch js-nav-section c-SectionOne-blur c-SectionOne-dataOne c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">impacts on innovation</h2>

            <div class="c-SectionOne-dataOne-desc u-w7of16 u-offset-l-w2of16 | u-w8of16@md | u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class="t-h4">88% of Business Executives agree innovation is more important now than ever. However, 42% feel COVID-19 has had a negative impact on their ability to innovate.</p>

                <div class="u-marg-t-sm u-flex u-flex-flow-row-wrap | u-marg-t-md@sm u-relative@sm">
                    <question-btn number="58" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                    <question-btn class="u-marg-l-xs" number="60" question="For each of the following events, please indicate to what extent, if any, these have had a positive or negative impact on the ability of your business to innovate over the past 6 months?" response="(NET Positive impact; NET Negative impact) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />

                    <div class="u-flex@sm u-relative@sm u-fit-w@sm u-marg-t-xs@sm">
                        <share-btn class="u-marg-l-xs | u-marg-l-0@sm" />
                    </div>
                </div>
            </div>

            <appear-object v-bind:ratio="0.3" v-bind:active="isPageReady" class="c-SectionOne-dataOne-content u-flex u-flex-flow-row-wrap u-align-items-c | u-align-items-s@md">
                <div class="c-SectionOne-dataOne-content-block u-w3of16 u-offset-l-w1of16 | u-w6of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionOne-dataOne-numbers">
                        <div ref="numberOne" class="c-SectionOne-dataOne-number c-SectionOne-dataOne-number--1 u-relative u-color--blue">
                            <div v-bind:class="'is-number-'+numberOne" class="u-relative u-inline-block">
                                <span v-for="n in 8" v-bind:key="n" v-bind:class="n != 8 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataOne-number-item">{{8-(8-n)}}</span>
                            </div>
                        </div>

                        <div ref="numberTwo" class="c-SectionOne-dataOne-number c-SectionOne-dataOne-number--2 u-color--blue">
                            <div v-bind:class="'is-number-'+numberTwo" class="u-relative u-inline-block">
                                <span v-for="n in 10" v-bind:key="n" v-bind:class="n != 10 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataOne-number-item">{{10-(10-n)}}</span>
                            </div>
                        </div>
                    </div>

                    <p class="text--sm">Over 8 in 10 Business Executives agree that recent changes in the working world mean companies need to change how they innovate.</p>

                    <div class="c-SectionOne-dataOne-buttons u-marg-t-sm | u-relative@sm">
                        <question-btn number="57" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>

                <div class="c-SectionOne-dataOne-content-block u-relative u-w8of16 | u-fit-w@md u-marg-l-0@md u-marg-y-xl@md">
                    <div class="c-SectionOne-dataOne-video">
                        <video-observer class="c-SectionOne-dataOne-video-container t-b-radius-lg">
                            <video-responsive>
                                <video class="js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/one/poster-impacts_on_innovation-mobile.jpg` : `${publicPath}static/images/one/poster-impacts_on_innovation.jpg`" preload="auto" loop muted playsinline>
                                    <source :src="`${publicPath}static/videos/one/impacts_on_innovation.webm`" type="video/webm">
                                    <source :src="`${publicPath}static/videos/one/impacts_on_innovation.mp4`" type="video/mp4">

                                    <source :srcset="`${publicPath}static/videos/one/impacts_on_innovation-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                    <source :srcset="`${publicPath}static/videos/one/impacts_on_innovation-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                                </video>
                            </video-responsive>
                        </video-observer>
                    </div>

                    <parallax-object v-bind:ratio="0.1" class="c-SectionOne-dataOne-img u-absolute u-pos-tr">
                        <div class="c-SectionOne-dataOne-img-inner t-b-radius-md">
                            <img :src="`${publicPath}static/images/one/impacts_on_innovation.png`" alt="" class="u-absolute u-pos-tl u-fit">
                        </div>
                    </parallax-object>
                </div>

                <div class="c-SectionOne-dataOne-content-block u-w3of16 | u-w6of16@md u-offset-l-w2of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionOne-dataOne-numbers">
                        <div ref="numberThree" class="c-SectionOne-dataOne-number c-SectionOne-dataOne-number--1 u-relative u-color--blue">
                            <div v-bind:class="'is-number-'+numberThree" class="u-relative u-inline-block">
                                <span v-for="n in 2" v-bind:key="n" v-bind:class="n != 2 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataOne-number-item">{{2-(2-n)}}</span>
                            </div>
                        </div>

                        <div ref="numberFour" class="c-SectionOne-dataOne-number c-SectionOne-dataOne-number--2 u-color--blue">
                            <div v-bind:class="'is-number-'+numberFour" class="u-relative u-inline-block">
                                <span v-for="n in 5" v-bind:key="n" v-bind:class="n != 5 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataOne-number-item">{{5-(5-n)}}</span>
                            </div>
                        </div>
                    </div>

                    <p class="text--sm">2 in 5 Business Executives fear a negative impact on innovation budgets in the future.</p>

                    <div class="c-SectionOne-dataOne-buttons u-marg-t-sm | u-relative@sm">
                        <question-btn number="59" v-bind:right="true" question="What impact do you think the global economic contraction caused by COVID-19 will have on innovation budgets in your industry over the next 2-3 years?" response="(NET Positive impact) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>

                <div class="c-SectionOne-dataOne-content-block u-w7of16 u-offset-l-w6of16 | u-w7of16@md u-offset-l-w1of16@md | u-w13of16@sm u-offset-l-w1of16@sm">
                    <p class="c-SectionOne-dataOne-text t-h4">Learn more about how this new innovation imperative is driving major change and focus on innovation throughout the world.</p>
                    <discover-btn v-bind:number="1" text="Discover" />
                </div>
            </appear-object>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && activeContentOverlay === 1 && !isFF, 'is-hidden': contentOverlayOpen && activeContentOverlay === 1 && isFF }" class="js-section js-nav-section c-SectionOne-blur c-SectionOne-dataTwo c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">need for speed?</h2>

            <div class="c-SectionOne-dataTwo-desc u-w6of16 u-offset-l-w2of16 | u-w7of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class="t-h4">84% of Business Executives agree that with greater importance comes a greater desire for speed of innovation.</p>

                <div class="u-marg-t-sm | u-relative@sm">
                    <question-btn number="58" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                    <share-btn class="u-marg-l-xs" />
                </div>
            </div>

            <div class="c-SectionOne-dataTwo-legend u-w3of16 u-offset-l-w6of16 | u-w6of8@sm u-offset-l-w1of8@sm">
                <p class="t-text--sm">When innovating, it is best...</p>
                <p class="t-text--sm u-opacity--50 u-italic u-marg-t-xs"><span class="u-hide@sm">Hover the graph</span><span class="u-hide | u-block@sm">Tap on the graph</span></p>
            </div>

            <div class="c-SectionOne-dataTwo-chart u-flex u-flex-flow-row-wrap u-align-items-c">
                <div class="c-SectionOne-dataTwo-chart-one u-w6of16 u-offset-l-w3of16 u-relative | u-w7of16@lg u-offset-l-w2of16@lg | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionOne-dataTwo-chart-one-bg u-absolute u-fit u-pos-tl t-b-radius-lg u-flex u-align-items-c u-justify-content-c">
                        <span class="c-SectionOne-dataTwo-chart-one-number">
                            61<sup class="c-SectionOne-dataTwo-chart-one-number-sup">%</sup>
                        </span>

                        <p class="c-SectionOne-dataTwo-chart-one-text u-absolute t-text--sm">...to wait until perfect and to test the innovation before launch to ensure the customer is completely satisfied from the start.</p>
                    </div>
                </div>

                <div class="c-SectionOne-dataTwo-chart-two u-w4of16 u-relative | u-w5of16@lg | u-w6of16@md | u-w5of8@sm u-offset-l-w2of8@sm">
                    <div class="c-SectionOne-dataTwo-chart-two-bg u-absolute u-fit u-pos-tl t-b-radius-md u-flex u-align-items-c u-justify-content-c">
                        <span class="c-SectionOne-dataTwo-chart-two-number">
                            39<sup class="c-SectionOne-dataTwo-chart-two-number-sup">%</sup>
                        </span>

                        <p class="c-SectionOne-dataTwo-chart-two-text u-absolute t-text--sm">...to get to market as quickly as possible to keep an edge on competition, even if this means having an imperfect product or service and improving it along the way.</p>
                    </div>
                </div>
            </div>

            <div class="u-w7of16 u-offset-l-w6of16 | u-w7of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                <p class="c-SectionOne-dataOne-text t-h4">61% of  Business Executives remain reluctant to sacrifice speed for quality.</p>

                <div class="u-marg-t-sm | u-relative@sm">
                    <question-btn number="35" question="When innovating, it is best..." response="[TRACKED BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                    <share-btn class="u-marg-l-xs" />
                </div>
            </div>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && activeContentOverlay === 2 }" class="js-section js-nav-section c-SectionOne-blur c-SectionOne-dataThree c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">COVID-19, Innovation & Healthcare</h2>

            <p class="c-SectionOne-dataThree-desc u-w6of16 u-offset-l-w2of16 | u-w7of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm t-h4">Healthcare is seen as leading the way in innovation, despite difficulties that those within the sector have felt in meeting challenges of the COVID-19 era.</p>

            <div class="c-SectionOne-dataThree-head u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberFive" class="c-SectionOne-dataThree-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberFive" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataThree-number-item">{{84-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">Say the types of industries leading on innovation are changing.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w6of8@sm u-offset-l-w1of8@sm u-relative@sm u-marg-t-md@sm | u-w14of16@sm u-offset-l-w1of16@sm">
                        <question-btn v-bind:right="true" number="57" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-relative u-fit-w">
                <div class="c-SectionOne-dataThree-video u-w10of16 u-offset-l-w5of16 | u-w12of16@md u-offset-l-w3of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <video-observer class="c-SectionOne-dataThree-video-container t-b-radius-lg">
                        <video-responsive>
                            <video class="js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/one/poster-covid19_innovation_healthcare-mobile.jpg` : `${publicPath}static/images/one/poster-covid19_innovation_healthcare.jpg`" preload="auto" loop muted playsinline>
                                <!-- <source :src="`${publicPath}static/videos/one/covid19_innovation_healthcare.webm" type="video/webm"> -->
                                <source :src="`${publicPath}static/videos/one/covid19_innovation_healthcare.mp4`" type="video/mp4">

                                <!-- <source :srcset="`${publicPath}static/videos/one/covid19_innovation_healthcare-mobile.webm`" type="video/webm" media="(max-width: 767px)"> -->
                                <source :srcset="`${publicPath}static/videos/one/covid19_innovation_healthcare-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </div>

                <parallax-object v-bind:ratio="0.1" class="c-SectionOne-dataThree-img u-w3of16 u-offset-l-w3of16 u-absolute u-pos-tl | u-offset-l-w1of16@md | u-w10of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionOne-dataThree-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/one/covid19_innovation_healthcare.png`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>

                <parallax-object v-bind:ratio="0.2" class="c-SectionOne-dataThree-gradient u-w3of16 u-offset-l-w2of16 u-absolute u-pos-tl | u-offset-l-w0of16@md | u-w5of16@sm u-offset-l-w4of8@sm">
                    <div class="c-SectionOne-dataThree-gradient-inner t-b-radius-sm"></div>
                </parallax-object>
            </div>

            <div class="c-SectionOne-dataThree-foot u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 | u-offset-l-w5of16@lg | u-offset-l-w4of16@md | u-w11of16@sm u-offset-l-w1of16@sm">
                    <div ref="numberSix" class="c-SectionOne-dataThree-number u-color--blue">
                        <div v-bind:class="'is-number-'+numberSix" class="u-relative u-inline-block">
                            <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataThree-number-item">{{82-(20-n)}}<sup>%</sup></span>
                        </div>
                    </div>
                </div>
                <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w6of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <div class="u-w11of16@sm u-offset-l-w1of16@sm">
                        <p class="t-text--sm">Say healthcare has set a great example for other industries to follow in speeding up innovation.</p>
                    </div>
                    <div class="u-marg-t-sm | u-w14of16@sm u-offset-l-w1of16@sm u-marg-t-md@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="57" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-w7of16 u-offset-l-w6of16 | u-w7of16@lg u-offset-l-w5of16@lg | u-w9of16@md u-offset-l-w4of16@md | u-w12of16@sm u-offset-l-w1of16@sm">
                <p class="c-SectionOne-dataThree-text t-h4">Discover which industries and countries have made significant progress in innovating due to the COVID-19 pandemic.</p>
                <discover-btn v-bind:number="2" text="Discover" />
            </div>
        </section>

        <next-section v-bind:url="'/sections/world-of-innovations-nations'" v-bind:label="'Section 02'" v-bind:title="'A World of Innovation Nations'" v-bind:class="{ 'is-blurred': contentOverlayOpen && activeContentOverlay === 2 }" class="c-SectionOne-blur" />

        <content-overlay v-bind:number="1">
            <div class="c-SectionOne-dataOneOverlay-cols">
                <div v-bind:class="{ 'is-active': activeQuestion === 1 }" class="c-SectionOne-dataOneOverlay-col">
                    <p class="c-SectionOne-dataOneOverlay-col-text t-text--sm">To meet these new demands, companies are increasing focus on innovation. This new innovation focus includes adaptation and innovation for new customers and markets at home and abroad.</p>

                    <div v-on:click="activeQuestion = 1" class="u-marg-t-sm | u-marg-t-md@sm u-fit@sm u-relative@sm">
                        <question-btn number="54" question="What changes have been made to innovation since COVID?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>

                <div v-bind:class="{ 'is-active': activeQuestion === 2 }" class="c-SectionOne-dataOneOverlay-col">
                    <p class="c-SectionOne-dataOneOverlay-col-text t-text--sm">The biggest concerns about future innovation progress relate to movement of people, goods and services (50%); investment restrictions (50%) and the impact on collaboration (36%).</p>

                    <div v-on:click="activeQuestion = 2" class="u-marg-t-sm | u-marg-t-md@sm u-fit@sm u-relative@sm">
                        <question-btn v-bind:right="true" number="62" question="Thinking about the potential challenges to innovation progress that may lie ahead, which of the following, would you say are a major cause for concern?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="c-SectionOne-dataOneOverlay-cols is-chart">
                <div class="c-SectionOne-dataOneOverlay-col">
                    <div ref="dataOneChart" class="c-SectionOne-dataOneOverlay-chart u-w3of4 | u-w4of4@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                        <div class="c-SectionOne-dataOneOverlay-chart-item c-SectionOne-dataOneOverlay-chart-item--0">
                            <div class="c-SectionOne-dataOneOverlay-chart-item-gray"></div>
                            <div class="c-SectionOne-dataOneOverlay-chart-item-blue"></div>
                        </div>

                        <div class="c-SectionOne-dataOneOverlay-chart-item c-SectionOne-dataOneOverlay-chart-item--1 is-selected">
                            <div class="c-SectionOne-dataOneOverlay-chart-item-gray"></div>
                            <div class="c-SectionOne-dataOneOverlay-chart-item-blue"></div>
                        </div>
                    </div>
                </div>

                <div class="c-SectionOne-dataOneOverlay-col c-SectionOne-dataOneOverlay-col--pct u-relative">
                    <div class="c-SectionOne-dataOneOverlay-legend is-selected">
                        <div class="c-SectionOne-dataOneOverlay-number u-color--blue">
                            <div class="u-relative u-inline-block">
                                <span class="c-SectionOne-dataOneOverlay-number-item">75<sup>%</sup></span>
                            </div>
                        </div>

                        <p class="t-text--sm">Believe the ability to innovate will be severely weakened if the COVID-19 crisis does not diminish soon.</p>
                    </div>
                </div>
            </div>

            <div class="c-SectionOne-dataOneOverlay-cols">
                <div class="c-SectionOne-dataOneOverlay-col is-full">
                    <p class="t-h4">An end to the COVID-19 crisis - along with both global and domestic recovery - is seen as vital to avoid a detrimental, long-term impact on innovation.</p>
                    <div class="u-marg-t-sm | u-marg-t-md@sm u-flex u-flex-flow-row-wrap | u-marg-b-xl@sm u-relative@sm">
                        <question-btn number="57" question="How far would you agree or disagree with the following statements?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>
            </div>
        </content-overlay>

        <content-overlay v-bind:number="2">
            <div>
                <div class="c-SectionOne-dataThreeOverlay-cols">
                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <div class="u-w13of16@sm">
                            <p class="t-text--sm">Business Executives believe healthcare has outperformed all other sectors for making progress on innovation during the pandemic.</p>
                        </div>

                        <div class="u-marg-t-sm | u-hide@sm">
                            <question-btn number="44" question="Which of the following industries, if any, do you think have made significant progress in relation to innovation in the last 6 months during the COVID-19 pandemic?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>

                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <ul class="c-SectionOne-dataThreeOverlay-lines t-list">
                            <li>
                                <div class="c-SectionOne-dataThreeOverlay-lines-item">
                                    <div class="c-SectionOne-dataThreeOverlay-lines-item-blue" style="width: 52%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">Healthcare<span class="u-color--blue u-color--blue u-marg-x-xxs">52%</span></p>
                            </li>
                            <li>
                                <div class="c-SectionOne-dataThreeOverlay-lines-item">
                                    <div class="c-SectionOne-dataThreeOverlay-lines-item-blue" style="width: 43%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">Telecoms / Internet<span class="u-color--blue u-color--blue u-marg-x-xxs">43%</span></p>
                            </li>
                            <li>
                                <div class="c-SectionOne-dataThreeOverlay-lines-item">
                                    <div class="c-SectionOne-dataThreeOverlay-lines-item-blue" style="width: 42%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">High Tech / IT<span class="u-color--blue u-color--blue u-marg-x-xxs">42%</span></p>
                            </li>
                        </ul>

                        <div class="u-hide | u-block@sm u-marg-t-md@sm u-fit@sm u-relative@sm">
                            <question-btn number="44" question="Which of the following industries, if any, do you think have made significant progress in relation to innovation in the last 6 months during the COVID-19 pandemic?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>
                </div>

                <div class="c-SectionOne-dataThreeOverlay-cols">
                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <div ref="numberSeven" class="c-SectionOne-dataThreeOverlay-number u-color--blue">
                            <div v-bind:class="'is-number-'+numberSeven" class="u-relative u-inline-block">
                                <span v-for="n in 20" v-bind:key="n" v-bind:class="n != 20 ? 'u-absolute u-pos-tl' : ''" class="c-SectionOne-dataThree-number-item">{{45-(20-n)}}<sup>%</sup></span>
                            </div>
                        </div>
                    </div>

                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <div class="u-w13of16@sm">
                            <p class="t-text--sm">of Business Executives feel the pace of innovation has increased over the last six months, with differing experiences across sectors. For instance, the majority of Business Executives feel the pace has increased within Health (58%) and Telecoms (53%), but only a minority feel it has increased in sectors like FMCG 37% and Energy (38%).</p>
                        </div>
                        <div class="u-marg-t-sm | u-fit@sm u-relative@sm u-marg-t-md@sm">
                            <question-btn v-bind:right="true" number="8" question="Do you believe the pace of innovation in your country has increased, stayed the same or decreased in the last 18 months?" response="[NEW JAN 2020 BE | ADAPTED SEPT 2020 BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                            <share-btn v-bind:right="true" class="u-marg-l-xs" />
                        </div>
                    </div>
                </div>

                <div class="c-SectionOne-dataThreeOverlay-cols">
                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <div class="u-w13of16@sm">
                            <p class="t-text--sm">The two innovation leaders have made the most significant progress in healthcare innovation in the last six months.</p>
                        </div>

                        <div class="u-marg-t-sm | u-hide@sm u-marg-t-md@sm u-fit@sm u-relative@sm">
                            <question-btn number="45" question="Which countries, if any, do you think have made significant progress on innovation within healthcare in the last 6 months during the COVID-19 pandemic?" response="[NEW SEPT 2020 BE]  Base: Business executives Sept 2020: 1,128" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>

                    <div class="c-SectionOne-dataThreeOverlay-col">
                        <ul class="c-SectionOne-dataThreeOverlay-lines t-list">
                            <li>
                                <div class="c-SectionOne-dataThreeOverlay-lines-item">
                                    <div class="c-SectionOne-dataThreeOverlay-lines-item-blue" style="width: 49%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">USA<span class="u-color--blue u-color--blue u-marg-x-xxs">49%</span></p>
                            </li>
                            <li class="u-marg-t-xs">
                                <div class="c-SectionOne-dataThreeOverlay-lines-item">
                                    <div class="c-SectionOne-dataThreeOverlay-lines-item-blue" style="width: 47%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">China<span class="u-color--blue u-color--blue u-marg-x-xxs">47%</span></p>
                            </li>
                        </ul>

                        <div class="u-hide | u-block@sm u-marg-t-md@sm u-fit@sm u-relative@sm">
                            <question-btn number="45" question="Which countries, if any, do you think have made significant progress on innovation within healthcare in the last 6 months during the COVID-19 pandemic?" response="[NEW SEPT 2020 BE]  Base: Business executives Sept 2020: 1,128" />
                            <share-btn class="u-marg-l-xs" />
                        </div>
                    </div>
                </div>
            </div>
        </content-overlay>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { gsap, Quad } from 'gsap';
    import scrollToY from '@/utilities/scrollToY';
    import getOffsetTop from '@/utilities/getOffsetTop';

    import '@damienmortini/generalelectric-ib2020-globe/src/index.js';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import ObjectFit from '@/components/ObjectFit';
    import VideoObserver from '@/components/VideoObserver';
    import NextSection from '@/components/NextSection';
    import AppearObject from '@/components/AppearObject';
    import SubHeader from '@/components/SubHeader';
    import QuestionBtn from '@/components/QuestionBtn';
    import VideoResponsive from '@/components/VideoResponsive';
    import ShareBtn from '@/components/ShareBtn';
    import DiscoverBtn from '@/components/DiscoverBtn';
    import ContentOverlay from '@/components/ContentOverlay';

    export default {
        name: 'Home',

        components: {
            CursorTrigger,
            ParallaxObject,
            ObjectFit,
            VideoObserver,
            NextSection,
            AppearObject,
            SubHeader,
            QuestionBtn,
            VideoResponsive,
            ShareBtn,
            DiscoverBtn,
            ContentOverlay,
        },

        props: {
            initIndex: { type: Number, default: 0 },
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
                isFromSections: false,
                isFromSection: false,

                activeQuestion: -1,

                numberOne: 0,
                numberTwo: 0,
                numberThree: 0,
                numberFour: 0,
                numberFive: 0,
                numberSix: 0,
                numberSeven: 0,
            };
        },

        computed: {
            ...mapState('window', [
                'width',
                'minHeight',
                'isFF',
            ]),

            ...mapState('main', [
                'activeContentOverlay',
                'contentOverlayOpen',
            ]),

            ...mapGetters('window', [
                'isMobile',
                'halfHeight'
            ]),
        },

        watch: {
            contentOverlayOpen: 'onContentOverlayOpenToggle',
        },

        beforeRouteEnter (to, from, next) {
            next((vm) => {
                if(from.meta.type === 'section') {
                    vm.isFromSection = true;
                }

                if (from.name === 'Sections') {
                    vm.isFromSections = true;
                }
            });
        },

        created() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.onNumberAppaer(entry.target);
                        this.observer.unobserve(entry.target);
                    }
                });
            });
        },

        mounted() {
            this.setCurrentNav(10);
            this.$eventHub.$on('page:ready', this.onPageReady);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('route:scroll', this.onRouteScroll);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');

                this.$navSections = Array.from(this.$el.querySelectorAll('.js-nav-section'));
            });

            this.observer.observe(this.$refs.numberOne);
            this.observer.observe(this.$refs.numberTwo);
            this.observer.observe(this.$refs.numberThree);
            this.observer.observe(this.$refs.numberFour);
            this.observer.observe(this.$refs.numberFive);
            this.observer.observe(this.$refs.numberSix);
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('route:scroll', this.onRouteScroll);

            this.observer.unobserve(this.$refs.numberOne);
            this.observer.unobserve(this.$refs.numberTwo);
            this.observer.unobserve(this.$refs.numberThree);
            this.observer.unobserve(this.$refs.numberFour);
            this.observer.unobserve(this.$refs.numberFive);
            this.observer.unobserve(this.$refs.numberSix);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav',
            ]),

            resize() {
                if (!this.$navSections) return;

                this.cuePoints = [];

                this.$navSections.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.halfHeight && this.$root.smoothScroll < nextCuePoints - this.halfHeight ) {
                        this.setCurrentNav(10+i);
                    }
                });
            },

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;

                if (this.initIndex) {
                    this.cuePoints[this.initIndex];

                    const offset = this.initIndex < 2 ? 0 : 160;
                    scrollToY(this.cuePoints[this.initIndex] - offset);
                }

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);
            },

            onRouteScroll(index) {
                if (!this.isPageReady) return;

                const offset = index < 2 ? 0 : 160;
                scrollToY(this.cuePoints[index] - offset);
            },

            onNumberAppaer(elem) {
                let init  = { value: 0 };
                let target = 20;

                if (elem === this.$refs.numberOne) {
                    target = 8;
                }

                if (elem === this.$refs.numberTwo) {
                    target = 10;
                }

                if (elem === this.$refs.numberThree) {
                    target = 2;
                }

                if (elem === this.$refs.numberFour) {
                    target = 5;
                }

                gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                    if (elem === this.$refs.numberOne) {
                        this.numberOne = Math.round(init.value);
                    }

                    if (elem === this.$refs.numberTwo) {
                        this.numberTwo = Math.round(init.value);
                    }

                    if (elem === this.$refs.numberThree) {
                        this.numberThree = Math.round(init.value);
                    }

                    if (elem === this.$refs.numberFour) {
                        this.numberFour = Math.round(init.value);
                    }

                    if (elem === this.$refs.numberFive) {
                        this.numberFive = Math.round(init.value);
                    }

                    if (elem === this.$refs.numberSix) {
                        this.numberSix = Math.round(init.value);
                    }
                } });
            },

            onContentOverlayOpenToggle() {
                let init  = { value: 0 };
                let target = 20;

                if (this.contentOverlayOpen) {
                    if (this.activeContentOverlay === 1) {
                        setTimeout(() => {
                            this.$refs.dataOneChart.classList.add('is-appear');
                        }, 600);
                    }

                    if (this.activeContentOverlay === 2) {
                        setTimeout(() => {
                            gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                                this.numberSeven = Math.round(init.value);
                            } });
                        }, 600);
                    }
                }

                if (!this.contentOverlayOpen) {
                    if (this.activeContentOverlay === 1) {
                        setTimeout(() => this.$refs.dataOneChart.classList.remove('is-appear'), 800);
                    }

                    if (this.activeContentOverlay === 2) {
                        setTimeout(() => this.numberSeven = init.value, 800);
                    }
                }
            },
        },
    }
</script>

<style lang="stylus">
    .c-SectionOne {

        &-blur {
            transition filter 0.5s $ease-out-quart 0.4s, background 0.5s $ease-out-quart 0.4s, opacity 0.5s $ease-out-quart 0.4s
            transform translate3d(0, 0, 0)
            opacity 1

            &.is-blurred {
                filter blur(60px)
                background #fafcfe

                @media $breakpoints.sm {
                    filter unset
                }
            }

            &.is-hidden {
                background #cacaca
                opacity 0.1
            }
        }

        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            .is-page-ready & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..4 {
                &--{n} {
                     transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                }
            }

            .is-from-sections & {
                opacity 1
                transform translateZ(0)
                transition none;
            }
        }

        &-intro {
            // z-index 0

            &-video {
                opacity 0
                transform translateZ(0)
                transition opacity 0.8s $ease-out-quart
                will-change opacity

                .is-page-ready & {
                    opacity 1
                }

                .is-from-section & {
                    opacity 1
                    transition none
                }
            }

            &-title {
                &-line {
                    &-2 {
                        @media $breakpoints.sm {
                            white-space: nowrap
                        }
                    }

                    &-3 {
                        margin-top 110px

                        @media $breakpoints.lg {
                            margin-top calc(110 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            margin-top calc(50 * 100vh / 1125)
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 150px
                    margin-bottom 95px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }
        }

        &-dataOne {
            // z-index 1
            padding-top 355px
            padding-bottom 305px

            @media $breakpoints.sm {
                padding-top 270px
                padding-bottom 270px
            }

            &-buttons {
                white-space nowrap

                @media $breakpoints.sm {
                    margin-top 30px
                    white-space unset
                }
            }

            &-desc {
                padding-top 145px
                padding-bottom 155px
            }

            &-content-block {
                @media $breakpoints.md {
                    &:first-of-type {
                        order 0
                    }

                    &:nth-of-type(2) {
                        order 2
                    }

                    &:nth-of-type(3) {
                        order 1
                    }

                    &:nth-of-type(4) {
                        order 3
                    }
                }

                @media $breakpoints.sm {
                    &:first-of-type {
                        order 0
                    }

                    &:nth-of-type(2) {
                        order 1
                    }

                    &:nth-of-type(3) {
                        order 2
                    }

                    &:nth-of-type(4) {
                        order 3
                    }
                }
            }

            &-img {
                margin-bottom 70px
                margin-right 4.168%
                padding-left 2.77%
                padding-right 1.39%
                width 56.25%

                @media $breakpoints.sm {
                    right 6.25%
                    bottom auto
                    margin-bottom 0
                    margin-right 0
                    padding-left 0
                    padding-right 0
                    width 84.375%
                }

                &-inner {
                    // aspect-ratio(446, 288)
                    aspect-ratio(393, 254)
                    overflow hidden
                }
            }

            &-video {
                padding 0 9.375%

                @media $breakpoints.sm {
                    padding 80px 0 0
                }

                &-container {
                    aspect-ratio(564, 710)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(678, 1086)
                    }

                    video {
                        // width 101%
                        object-fit cover
                    }
                }
            }

            // &-img {
            //     width 56.25%
            //     margin-right 4.168%

            //     @media $breakpoints.sm {
            //         margin-right 0
            //         width 84.375%
            //     }

            //     &-inner {
            //         aspect-ratio(393, 254)
            //         overflow hidden
            //     }
            // }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                &--1 {
                    padding-bottom 10px
                    text-align center

                    &::before {
                        content ''
                        position absolute
                        bottom 0
                        left 50%
                        transform translateX(-50%)
                        opacity 0.15
                        width 66.66666667%
                        height 1px
                        background $colors['black']
                    }
                }

                &--2 {
                    padding-top 27.5px
                    padding-bottom 23px
                    text-align center
                }

                &-item {
                    opacity 0
                    will-change opacity
                    transform translateZ(0)

                    for n in (0..20) {
                        &:nth-child({n}) {
                            .is-number-{n} & {
                                opacity 1
                            }
                        }
                    }
                }
            }

            &-text {
                padding-top 160px

                @media $breakpoints.md {
                    &:nth-of-type(2) {
                        padding-top 0
                    }
                }

                @media $breakpoints.sm {
                    padding-top 75px
                }
            }
        }

        &-dataOneOverlay {
            &-cols {
                display flex
                justify-content space-between
                // margin-top 85px

                @media $breakpoints.sm {
                    display block
                    // margin-top 120px
                }

                &.is-chart {
                    margin-top 180px !important
                }
            }

            &-cols + &-cols {
                margin-top 25px

                @media $breakpoints.sm {
                    margin-top 50px
                }
            }

            &-col {
                width calc(50% - 15px)

                @media $breakpoints.sm {
                    width 100%

                    &:nth-child(2) {
                        margin-top 80px
                    }
                }

                &.is-active {
                    position relative
                    z-index 1
                }

                &-text {
                    @media $breakpoints.sm {
                        width 87.5%
                    }
                }

                &.is-full {
                    width: 100%
                }

                &--pct {
                    @media $breakpoints.sm {
                        &:nth-child(2) {
                            margin-top 0
                        }
                    }
                }
            }

            &-chart {
                position relative
                height 290px
                // margin-top 75px

                // @media $breakpoints.sm {
                //     margin-top 125px
                //     margin-bottom 80px
                // }

                &-item {
                    position absolute
                    top 0
                    left 0

                    height 208px;
                    width 35px

                    opacity 0
                    will-change transform, opacity
                    transform scaleX(-1) scaleY(0) translateZ(0)
                    transform-origin bottom
                    transition transform 0.8s $ease-out-quart, opacity 0.8s $ease-out-quart

                    .c-SectionOne-dataOneOverlay-chart.is-appear & {
                        opacity 1
                        transform scaleX(-1) scaleY(1) translateZ(0)
                    }

                    for n in (0..2) {
                        &:nth-child({n}) {
                            transition transform 0.8s $ease-out-quart (n * 0.05s), opacity 0.8s $ease-out-quart (n * 0.05s)
                        }
                    }

                    &--0 {
                        top -70px
                        left 51%

                        @media $breakpoints.lg {
                            left 32%
                        }

                        @media $breakpoints.md {
                            left 28%
                        }

                        @media $breakpoints.sm {
                            left 30%
                        }
                    }

                    &--1 {
                        left 70%

                        @media $breakpoints.lg {
                            left 48%
                        }

                        @media $breakpoints.md {
                            left 44%
                        }

                        @media $breakpoints.sm {
                            left 45%
                        }
                    }

                    &-gray {
                        position absolute
                        top 0
                        left 0

                        height 100%;
                        width: 100%;

                        background: linear-gradient(to bottom, #cbced0, #FFFFFF)
                        bottom: 0;
                        margin-left: 12px;
                        transform-origin: 0 0;
                        transform: skewY(-20deg);

                        // bar top side
                        &:before {
                            content ""
                            position: absolute;
                            bottom: 100%;
                            left: 0;

                            background-color #e2e3e3

                            transform-origin: 0 100%;
                            transform: skewX(50deg);
                            width: 100%;
                            height 10px
                        }

                        // bar right side
                        &:after {
                            content: '';
                            background: linear-gradient(to bottom, #d3d5d7, #FFFFFF)
                            bottom: 10px;
                            height: 100%;
                            left: -12px;
                            position: absolute;
                            width: 12px;
                            transform-origin: 0 0;
                            transform: skewY(40deg);
                        }
                    }

                    &-blue {
                        position absolute
                        top 0
                        left 0

                        height 100%;
                        width: 100%;

                        background: linear-gradient(to bottom, #1171c5, #FFFFFF)
                        bottom: 0;
                        margin-left: 12px;
                        transform-origin: 0 0;
                        transform: skewY(-20deg) translateZ(0);

                        opacity 0
                        will-change opacity
                        transition opacity 0.6s $ease-out-quart

                        .is-selected & {
                            opacity 1
                        }

                        // bar top side
                        &:before {
                            content ""
                            position: absolute;
                            bottom: 100%;
                            left: 0;

                            background-color #0780dd

                            transform-origin: 0 100%;
                            transform: skewX(50deg);
                            width: 100%;
                            height 10px
                        }

                        // bar right side
                        &:after {
                            content: '';
                            background: linear-gradient(to bottom, #106dbf, #FFFFFF)
                            bottom: 10px;
                            height: 100%;
                            left: -12px;
                            position: absolute;
                            width: 12px;
                            transform-origin: 0 0;
                            transform: skewY(40deg);
                        }
                    }
                }
            }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                sup {
                    top -0.5em
                    font-size 60%
                }

                &-item {
                    opacity 1
                }
            }

            &-legend {
                opacity 0
                transform translateZ(0)
                will-change opacity
                transition opacity 0.6s $ease-out-quart

                &.is-selected {
                    opacity 1
                }
            }
        }

        &-dataTwo {
            padding-bottom 320px

            @media $breakpoints.sm {
                padding-bottom 280px
            }

            &-desc {
                padding-top 145px
                padding-bottom 115px

                @media $breakpoints.sm {
                    padding-bottom 145px
                }
            }

            &-legend {
                padding 0 3.125%

                @media $breakpoints.sm {
                    padding 0
                }
            }

            &-chart {
                padding-top 85px;
                color $colors.white
                pointer-events none

                @media $breakpoints.sm {
                    padding-top 75px
                }

                &-one {
                    aspect-ratio(593, 413)
                    pointer-events auto

                    @media $breakpoints.sm {
                        aspect-ratio(678, 600)
                    }

                    &-bg {
                        transition transform 0.8s $ease-out-quart
                        will-change transform
                        background-color $colors['blue']
                    }

                    &-number {
                        font-size 11.528vw
                        backface-visibility hidden
                        transform scale(1) translateZ(0)
                        transition transform 0.8s $ease-out-quart
                        will-change transform

                        @media $breakpoints.sm {
                            font-size 42.133vw
                        }

                        &-sup {
                            top -1em
                            font-size 6vw
                            vertical-align: sub;

                            @media $breakpoints.sm {
                                font-size 22.5vw
                            }
                        }
                    }

                    &-text {
                        top 60%
                        width 57.14285714%
                        // visibility hidden
                        opacity 0
                        transform translateY(4vw)
                        transition opacity 0.8s $ease-out-quart, transform 0.8s $ease-out-quart
                        will-change opacity, transform

                        @media $breakpoints.lg {
                            width 70%
                        }

                        @media $breakpoints.sm {
                            transform translateY(18vw)
                        }
                    }
                }

                &-two {
                    aspect-ratio(302, 195)
                    pointer-events auto

                    @media $breakpoints.sm {
                        aspect-ratio(678, 600)
                        margin-top 1.5vw
                    }

                    &-bg {
                        left 2.5%
                        transition transform 0.8s $ease-out-quart
                        will-change transform
                        background-color $colors['blue-light']
                    }

                    &-number {
                        font-size 2.917vw
                        transform scale(1) translateZ(0)
                        backface-visibility hidden
                        transition transform 0.8s $ease-out-quart
                        will-change transform

                        @media $breakpoints.sm {
                            font-size 8.533vw
                        }

                        &-sup {
                            top -1.15em
                            font-size 1.5vw
                            vertical-align: sub;

                            @media $breakpoints.sm {
                                font-size 4.3vw
                            }
                        }
                    }

                    &-text {
                        top 52%
                        width 87%
                        // visibility hidden
                        opacity 0
                        transform translateY(2.5vw)
                        transition opacity 0.8s $ease-out-quart, transform 0.8s $ease-out-quart
                        will-change opacity, transform

                        @media $breakpoints.xl {
                            top 46%
                            width 90%
                        }

                        @media $breakpoints.lg {
                            top 46%
                            width 90%
                            transform translateY(2vw)
                        }

                        @media $breakpoints.sm {
                            top 37%
                            width 107%
                            transform translateY(10vw)
                        }
                    }
                }

                &:hover &-one-bg {
                    transform translateX(-7%)

                    @media $breakpoints.sm {
                        transform translateX(0) translateY(-7%)
                    }
                }

                &:hover &-two-bg {
                    transform translateX(15%)

                    @media $breakpoints.sm {
                        transform translateX(0) translateY(15%)
                    }
                }

                &:hover &-one:hover &-one-bg {
                    transform translateY(0) scale(1.2)
                }

                &:hover &-one:hover &-one-number {
                    transform scale(calc(1/1.2)) translateY(-40px)
                }

                &:hover &-one:hover &-one-text {
                    visibility visible
                    opacity 1
                    transform translateY(0) scale(calc(1/1.2))
                }

                &:hover &-two:hover &-two-bg {
                    transform translateY(0) scale(1.2)
                }

                &:hover &-two:hover &-two-number {
                    transform scale(calc(1/1.2)) translateY(-40px)

                    @media $breakpoints.sm {
                        transform scale(calc(1/1.2)) translateY(-60px)
                    }
                }

                &:hover &-two:hover &-two-text {
                    visibility visible
                    opacity 1
                    transform translateY(0) scale(calc(1/1.2))
                }
            }

            &-text {
                padding-top 160px

                @media $breakpoints.sm {
                    padding-top 90px
                }
            }
        }

        &-dataThree {
            padding-bottom 320px

            @media $breakpoints.sm {
                padding-bottom 280px
            }

            &-desc {
                padding-top 145px
                padding-bottom 140px

                @media $breakpoints.sm {
                    padding-bottom 120px
                }
            }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                sup {
                    top -0.5em
                    font-size 60%
                }

                &-item {
                    opacity 0
                    will-change opacity
                    transform translateZ(0)

                    for n in (0..20) {
                        &:nth-child({n}) {
                            .is-number-{n} & {
                                opacity 1
                            }
                        }
                    }
                }
            }

            &-head {
                padding-bottom 80px
            }

            &-cols {
                margin 85px 0

                @media $breakpoints.sm {
                    display block
                    margin 120px 0
                }
            }

            &-col {
                width calc(50% - 25px)

                @media $breakpoints.lg {
                    &:first-child {
                        width calc(40% - 25px)
                    }

                    &:nth-child(2) {
                        width calc(60% - 25px)
                    }
                }

                @media $breakpoints.sm {
                    width 100% !important
                }
            }

            &-video {
                padding-right 2.78%

                @media $breakpoints.sm {
                    padding-right 0
                    padding-bottom 210px
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-img {
                top 55px

                @media $breakpoints.sm {
                    top auto
                    bottom 0
                }

                &-inner {
                    aspect-ratio(254, 330)
                    overflow hidden
                }
            }

            &-gradient {
                top 30px
                // padding 0 2.78%
                width 13.19%
                margin-left: 15.28%;
                backdrop-filter: blur(7px);

                @media $breakpoints.md {
                    padding 0
                    margin-left: 12.5%;
                    width 18.75%
                }

                @media $breakpoints.sm {
                    top auto
                    bottom 95px
                    margin-left 50%
                    width 31.25%
                }

                &-inner {
                    aspect-ratio(170, 120)
                    overflow hidden

                    backdrop-filter: blur(7px);
                    background linear-gradient(to top, #0060b4, rgba(#0eb5df, 0.1));
                }
            }

            &-foot {
                padding-top 115px

                @media $breakpoints.sm {
                    padding-top 90px
                }
            }

            &-text {
                padding-top 80px

                @media $breakpoints.sm {
                    padding-top 150px
                }
            }
        }

        &-dataThreeOverlay {
            &-cols {
                display flex
                justify-content space-between
                position relative
                margin-top 65px

                &:first-child {
                    z-index 3
                    margin-top 0
                }

                &:nth-child(2) {
                    z-index 2
                }

                &:nth-child(3) {
                    z-index 1
                }

                @media $breakpoints.sm {
                    display block
                    margin-top 115px

                    &:nth-child(2) {
                        margin-top 105px
                    }
                }
            }

            &-cols:nth-child(2) &-col:nth-child(2) {
                @media $breakpoints.sm {
                    margin-top 5px
                }
            }

            &-col {
                width calc(50% - 15px)

                @media $breakpoints.sm {
                    width 100%

                    &:nth-child(2) {
                        margin-top 45px
                    }
                }
            }

            &-lines {
                li + li {
                    margin-top 10px
                }

                .t-text--xxs {
                    letter-spacing 0.05em
                }

                &-item {
                    overflow hidden
                    transform translateZ(0)
                    position relative
                    height 4px
                    width 100%
                    background $colors['gray'];
                    margin-bottom 8px

                    &-blue, &-cyan {
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%

                        will-change transform
                        transform-origin left
                        transform scaleX(0) translateZ(0)
                        transition transform 0.8s $ease-out-quart

                        .is-appear & {
                            transform scaleX(1) translateZ(0)
                        }
                    }

                    &-blue {
                        background $colors['blue'];
                    }

                    &-cyan {
                        background $colors['blue-light'];
                    }
                }
            }

            &-number {
                line-height 1
                font-size 11.528vw

                @media $breakpoints.lg {
                    font-size 13.5vw
                }

                @media $breakpoints.md {
                    font-size 19vw
                }

                @media $breakpoints.sm {
                    font-size 40.8vw
                }

                sup {
                    top -0.5em
                    font-size 60%
                }

                &-item {
                    opacity 0
                    will-change opacity
                    transform translateZ(0)

                    for n in (0..20) {
                        &:nth-child({n}) {
                            .is-number-{n} & {
                                opacity 1
                            }
                        }
                    }
                }
            }
        }
    }
</style>