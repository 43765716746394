<template>
    <div class="c-SectionTwo u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady, 'is-from-sections': isFromSections, 'is-from-section': isFromSection }">

        <sub-header v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="c-SectionTwo-blur" v-bind:active="isPageReady" v-bind:number="2" v-bind:title="'A World of Innovation Nations'"></sub-header>

        <section class="js-section js-ui-switch js-nav-section c-SectionTwo-intro u-relative u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="c-Container u-absolute u-pos-tl u-fit u-flex u-align-items-c | u-align-items-fe@sm">
                <object-fit v-bind:width="1280" v-bind:height="720" v-bind:width-mobile="374" v-bind:height-mobile="664" class="c-SectionTwo-intro-video u-bg--blue u-fixed u-pos-tl u-fit">
                    <video-observer class="u-absolute u-pos-tl u-fit">
                        <video-responsive>
                            <video class="js-object-fit js-video u-absolute u-pos-tl u-fit" v-bind:poster="isMobile ? `${publicPath}static/images/two/poster-intro-mobile.jpg` : `${publicPath}static/images/two/poster-intro.jpg`" autoplay loop muted playsinline>
                                <source :src="`${publicPath}static/videos/section2.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/section2.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/section2-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/section2-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </object-fit>

                <div class="u-relative u-w8of16 u-offset-l-w4of16 u-force-3d  | u-w10of16@lg u-offset-l-w3of16@lg | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-SectionTwo-intro-title t-h1">
                        <span class="c-SectionTwo-line c-SectionTwo-line--0 c-SectionTwo-intro-title-line-1 u-block | u-offset-l-w1of10@lg | u-marg-l-0@sm">A World of</span>
                        <span class="c-SectionTwo-line c-SectionTwo-line--1 c-SectionTwo-intro-title-line-2 u-block u-offset-l-w3of8 | u-offset-l-w2of8@sm">Innovation</span>
                        <span class="c-SectionTwo-line c-SectionTwo-line--2 c-SectionTwo-intro-title-line-3 u-hide | u-block@sm u-offset-l-w1of8@sm">Nations</span>
                    </h1>

                    <div class="u-relative u-flex u-fit-w">
                        <div class="u-w5of8 u-text-right u-pad-r-lg | u-hide@sm">
                            <span class="c-SectionTwo-line c-SectionTwo-line--2 c-SectionTwo-intro-title-line-3 t-h1 u-block">Nations</span>
                        </div>

                        <div class="c-SectionTwo-intro-desc u-w3of8 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-SectionTwo-line c-SectionTwo-line--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-SectionTwo-intro-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 02</span>
                                </span>
                            </div>

                            <p class="c-SectionTwo-line c-SectionTwo-line--4 c-SectionTwo-intro-text t-text--sm">Countries have more authority over their innovation strategies and growing confidence to innovate on their own.</p>
                        </div>
                    </div>
                </div>
                <div class="c-SectionTwo-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-SectionTwo-intro-scroll-btn c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section class="js-section js-ui-switch js-nav-section c-SectionTwo-dataOne c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Interconnected across the world</h2>

            <p class="c-SectionTwo-dataOne-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md | u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Globalization, interconnectedness, access to technology and trade have enabled more markets to have innovation-conducive environments.</p>

            <div class="u-relative u-fit-w">
                <parallax-object v-bind:ratio="0.1" class="c-SectionTwo-dataOne-img u-absolute u-pos-bl u-w6of16 | u-w13of16@sm u-offset-l-w1of16">
                    <div class="c-SectionTwo-dataOne-img-inner">
                        <img :src="`${publicPath}static/images/two/1.jpg`" alt="" class="u-absolute u-pos-tl u-fit t-b-radius-md">
                    </div>
                </parallax-object>

                <div class="c-SectionTwo-dataOne-webgl u-w10of16 u-offset-l-w4of16 | u-fit-w@sm u-marg-l-0@sm">
                    <cursor-trigger v-bind:name="'spin'" v-bind:active="!hasInteractedGlobe" v-bind:class="{ 'is-cursor-spin': !hasInteractedGlobe }" class="c-SectionTwo-dataOne-webgl-inner u-block t-b-radius-lg">
                        <generalelectric-ib2020-globe v-on="globeHandlers" ref="globe" :baseuri="publicPath" mode="trade" v-bind:zoom="width < 1024 ? 0.8 : 1" transparent class="u-absolute u-pos-tl u-fit"></generalelectric-ib2020-globe>

                        <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-spin u-absolute u-hide u-align-items-c u-justify-content-c | u-flex@sm">
                            <span class="c-Cursor-spin-label u-relative t-text--sm u-color--black">Spin</span>
                            <div class="c-Cursor-spin-icon u-absolute u-pos-tl u-fit u-flex u-align-items-c u-justify-content-c">
                                <img class="c-Cursor-spin-icon-img u-block u-force-3d" :src="`${publicPath}static/images/icon-spin.png`" alt="" width="64" height="56">
                            </div>
                        </div>
                    </cursor-trigger>
                </div>
            </div>

            <div class="c-SectionTwo-dataOne-switch u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="c-Switch-legend t-text--sm u-opacity--50 u-italic">Select categories</p>
                    <div class="u-relative">
                        <p v-bind:class="{ 'is-active': globeType === 'trade' }" class="c-SectionTwo-dataOne-switch-item t-text--sm u-absolute u-pos-tl">Navigate around the globe to see how trade flows around the world.</p>
                        <p v-bind:class="{ 'is-active': globeType === 'phone' }" class="c-SectionTwo-dataOne-switch-item t-text--sm">Navigate around the globe to see the mobile phone penetration by country.</p>
                        <p v-bind:class="{ 'is-active': globeType === 'internet' }" class="c-SectionTwo-dataOne-switch-item t-text--sm u-absolute u-pos-tl">Navigate around the globe to see the world internet penetration rate.</p>
                    </div>
                    <div class="u-relative">
                        <share-btn class="u-marg-t-sm" />
                    </div>
                </div>

                <div class="c-Switch u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w6of8@sm u-offset-l-w1of8@sm u-marg-t-lg@sm">
                    <ul class="t-list">
                        <li><button v-on:click="onGlobeChange('trade')" v-bind:class="{ 'is-active': globeType === 'trade' }" type="button" class="c-Switch-btn t-btn t-text--sm u-uppercase u-font-bold">Trade Flows</button></li>
                        <li><button v-on:click="onGlobeChange('phone')" v-bind:class="{ 'is-active': globeType === 'phone' }" type="button" class="c-Switch-btn t-btn t-text--sm u-uppercase u-font-bold">Mobile phone penetration</button></li>
                        <li><button v-on:click="onGlobeChange('internet')" v-bind:class="{ 'is-active': globeType === 'internet' }" type="button" class="c-Switch-btn t-btn t-text--sm u-uppercase u-font-bold">population using internet</button></li>
                    </ul>
                </div>

                <div class="c-SectionTwo-dataOne-switch-text u-relative u-w6of16 u-offset-l-w6of16 | u-offset-l-w7of16@lg | u-w7of16@md | u-w7of8@sm u-offset-l-w1of16@sm">
                    <p v-bind:class="{ 'is-active': globeType === 'trade' }" class="c-SectionTwo-dataOne-switch-text-p t-h4">A very large part of world trade is clustered around three regions: North America, Europe and East Asia. Even though the COVID-19 pandemic is resulting in a dramatic decline in trade.</p>
                    <p v-bind:class="{ 'is-active': globeType === 'phone' }" class="c-SectionTwo-dataOne-switch-text-p t-h4 u-absolute u-pos-tl">In 2020, the number of smartphone users in the world is 3.5 Billion, which translates to 45.12% of the world’s population owning a smartphone.</p>
                    <p v-bind:class="{ 'is-active': globeType === 'internet' }" class="c-SectionTwo-dataOne-switch-text-p t-h4 u-absolute u-pos-tl">The world Internet usage has grown by 1266% from 2000 to 2020. North America is where the penetration rate is the highest, with 90.3% of the population using Internet.</p>
                </div>
            </div>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="js-section js-nav-section c-SectionTwo-blur c-SectionTwo-dataTwo c-Container u-relative u-bg--white u-overflow-h">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">Increasing pride and confidence</h2>

            <p class="c-SectionTwo-dataTwo-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Although the pandemic has posed a challenge to innovation pace, Business Executives have successfully created innovation-conducive environments.</p>

            <cursor-trigger v-bind:name="'drag'" v-bind:active="!hasInteractedGraph" v-bind:class="{ 'is-cursor-drag': !hasInteractedGraph }" class="c-SectionTwo-dataTwo-graphs u-block u-relative u-fit-w">
                <appear-object v-bind:active="isPageReady" class="u-block">
                    <div v-on="graphHandlers" v-bind:class="{ 'is-active': graphActive === 0 }" class="c-SectionTwo-dataTwo-graph">
                        <default-slider ref="sliderOne" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(item, i) in graphOne" v-bind:key="i" class="js-item c-SectionTwo-dataTwo-bar u-inline-block u-w1of12">
                                    <div class="c-SectionTwo-dataTwo-bar-inner">
                                        <cursor-trigger v-bind:active="hasInteractedGraph" v-bind:name="'graph'" v-bind:value="item.cursorLabel" class="c-SectionTwo-dataTwo-bar-color" v-bind:style="{ height: item.value / graphOneMax * 100 + '%' }">
                                            <div class="c-SectionTwo-dataTwo-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                            <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                        </cursor-trigger>
                                        <div v-if="item.cursorLabel !== ''" class="c-SectionTwo-dataTwo-bar-diff has-drop-shadow u-absolute u-pos-bl u-hide u-align-items-c u-justify-content-c u-bg--white | u-flex@sm">
                                            <span class="t-text--sm u-color--black u-font-bold">{{item.cursorLabel}}</span>
                                        </div>
                                    </div>
                                    <p class="c-SectionTwo-dataTwo-graph-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>

                    <div v-on:pointerdown="hasInteractedGraph = true" v-bind:class="{ 'is-active': graphActive === 1 }" class="c-SectionTwo-dataTwo-graph u-absolute u-fit-w u-pos-tl">
                        <default-slider ref="sliderTwo" class="u-select-none">
                            <div class="u-w12of16 u-offset-l-w4of16 u-white-space-nowrap u-vacuum | u-w15of16@sm u-offset-l-w1of16@sm">
                                <div v-for="(item, i) in graphTwo" v-bind:key="i" class="js-item c-SectionTwo-dataTwo-bar u-inline-block u-w1of12">
                                    <div class="c-SectionTwo-dataTwo-bar-inner">
                                        <cursor-trigger v-bind:active="hasInteractedGraph && item.cursorLabel !== ''" v-bind:name="'graph'" v-bind:value="item.cursorLabel" v-bind:theme="'dark'" class="c-SectionTwo-dataTwo-bar-color" v-bind:style="{ height: item.value / graphTwoMax * 100 + '%' }">
                                            <div class="c-SectionTwo-dataTwo-bar-color-bg u-absolute u-pos-tl u-fit" v-bind:class="'is-' + item.color"></div>
                                            <p class="u-relative t-text--sm u-text-center u-color--white u-pad-a-sm">{{item.value}}%</p>
                                        </cursor-trigger>
                                        <div v-if="item.cursorLabel !== ''" class="c-SectionTwo-dataTwo-bar-diff u-absolute u-pos-bl u-hide u-align-items-c u-justify-content-c u-bg--black | u-flex@sm">
                                            <span class="t-text--sm u-color--white">{{item.cursorLabel}}</span>
                                        </div>
                                    </div>
                                    <p class="c-SectionTwo-dataTwo-graph-label t-text--xxs u-font-bold u-uppercase u-text-center">{{item.label}}</p>
                                </div>
                            </div>
                        </default-slider>
                    </div>
                </appear-object>

                <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-drag u-absolute u-hide u-align-items-c u-justify-content-c | u-flex@sm">
                    <span class="c-Cursor-drag-label u-relative t-text--sm u-color--black">Drag</span>
                    <div class="c-Cursor-drag-icon u-absolute u-flex u-align-items-c u-justify-content-sb">
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                        <span class="c-Cursor-drag-icon-arrow">
                            <span class="c-Cursor-drag-icon-arrow-line"></span>
                        </span>
                    </div>
                </div>

            </cursor-trigger>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-relative u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <p class="c-Switch-legend t-text--sm u-opacity--50 u-italic">Select categories and drag the graph</p>
                </div>
                <div class="c-Switch u-relative u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w6of8@sm u-offset-l-w1of8@sm u-marg-t-md@sm u-marg-b-lg@sm">
                    <ul class="t-list u-absolute u-pos-tl | u-relative@sm">
                        <li><button v-on:click="graphActive = 0" v-bind:class="{ 'is-active': graphActive === 0 }" type="button" class="c-Switch-btn t-btn t-text--sm u-uppercase u-font-bold">Countries claiming to be self-sufficient</button></li>
                        <li><button v-on:click="graphActive = 1" v-bind:class="{ 'is-active': graphActive === 1 }" type="button" class="c-Switch-btn t-btn t-text--sm u-uppercase u-font-bold">Increase in the pace of innovation</button></li>
                    </ul>
                </div>
            </div>

            <div class="u-flex u-flex-flow-row-wrap">
                <div class="u-relative u-w3of16 u-offset-l-w2of16 | u-w4of16@lg | u-w5of16@md u-offset-l-w1of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                    <div class="u-relative">
                        <div v-bind:class="{ 'is-active': graphActive === 0 }" class="c-SectionTwo-dataTwo-graphs-desc">
                            <p class="t-text--sm">My country is self-sufficient, and we don’t need to rely on other countries to innovate (% who agree).</p>
                            <p class="t-text--sm u-marg-t-md">+  -  Change, Jan 2020 to Sept 2020 due to the COVID-19 pandemic</p>
                            <div class="u-marg-t-sm | u-relative@sm">
                                <question-btn number="16" question="To what extent do you agree or disagree with the following statements related to innovation?" response="(NET Agree) [NEW JAN 2020 BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                                <share-btn class="u-marg-l-xs" />
                            </div>
                        </div>
                        <div v-bind:class="{ 'is-active': graphActive === 1 }" class="c-SectionTwo-dataTwo-graphs-desc u-absolute u-pos-tl u-fit-w">
                            <p class="t-text--sm">% Who believe the pace of innovation has increased in their country</p>

                            <ul class="t-list u-marg-t-sm">
                                <li class="t-text--sm u-relative"><span class="c-SectionTwo-dataTwo-legend u-bg--blue-light"></span><span class="c-SectionTwo-dataTwo-legend u-bg--blue"></span>in the last 18 months, Jan 2020</li>
                                <li class="t-text--sm u-relative"><span class="c-SectionTwo-dataTwo-legend u-bg--black"></span>in the last 6 months, Sept 2020</li>
                            </ul>

                            <div class="u-marg-t-sm | u-relative@sm">
                                <question-btn number="8" question="Do you believe the pace of innovation in your country has increased, stayed the same or decreased in the last 18 months? Do you believe the COVID-19 pandemic has caused the pace of innovation in your country to increase, stay the same or decrease in the last 6 months?" response="[NEW JAN 2020 BE | ADAPTED SEPT 2020 BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                                <share-btn class="u-marg-l-xs" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="u-w7of16 u-offset-l-w1of16 | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div class="c-SectionTwo-dataTwo-desc c-SectionTwo-dataTwo-desc--alt">
                        <p class="t-h4">Geographically, we are seeing the rise of the East as an innovation hotspot, as well as growing confidence in the Middle East.</p>
                        <p class="c-SectionTwo-dataTwo-desc-paragraph t-text--sm">Discover more about how the COVID-19 pandemic has impacted the pace of innovation.</p>
                        <discover-btn v-bind:number="1" text="Discover" />
                    </div>
                </div>
            </div>
        </section>

        <section v-bind:class="{ 'is-blurred': contentOverlayOpen && !isFF, 'is-hidden': contentOverlayOpen && isFF }" class="js-section js-nav-section c-SectionTwo-blur c-SectionTwo-dataThree c-Container u-relative u-bg--white">
            <h2 class="t-h3 u-w6of16 u-offset-l-w6of16 | u-offset-l-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">A sustainable future</h2>

            <p class="c-SectionTwo-dataThree-desc t-h4 u-w7of16 u-offset-l-w2of16 | u-w8of16@md u-offset-l-w1of16@md | u-w14of16@sm u-offset-l-w1of16@sm">The role of innovation in creating sustainable futures and its impact at a societal level are key measures of its success. COVID-19 gives added impetus to this societal imperative.</p>

            <div class="c-SectionTwo-dataThree-two-col u-flex u-flex-flow-row-wrap">
                <div class="u-w3of16 u-offset-l-w6of16 u-relative | u-w4of16@lg | u-w5of16@md u-offset-l-w5of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <p class="t-text--sm">86% of Business Executives believe it is more important than ever for innovation to focus on the challenges facing society and public health.</p>
                    <div class="u-marg-t-sm | u-relative@sm">
                        <question-btn number="58" question="Thinking about the needs and priorities of innovation going forwards, to what extent would you agree or disagree with the following?" response="(NET Agree) [NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128)" />
                        <share-btn class="u-marg-l-xs" />
                    </div>
                </div>

                <div class="u-w3of16 u-relative | u-w4of16@lg | u-w5of16@md | u-w14of16@sm u-offset-l-w1of16@sm u-marg-t-lg@sm">
                    <p class="t-text--sm">71% of Business Executives agree innovations that prioritize a long-term societal or environmental benefit are the best way to innovate.</p>
                    <div class="u-marg-t-sm | u-relative@sm">
                        <question-btn v-bind:right="true" number="35" question="When innovating, it is best..." response="[TRACKED BE] Base: Business executives Sept 2020: 1,128)" />
                        <share-btn v-bind:right="true" class="u-marg-l-xs" />
                    </div>
                </div>
            </div>

            <div class="u-relative u-fit-w">
                <parallax-object v-bind:ratio="0.1" class="c-SectionTwo-dataThree-img u-w5of16 u-absolute u-pos-br u-offset-r-w1of16 | u-w10of16@sm u-offset-l-w5of16@sm">
                    <div class="c-SectionTwo-dataThree-img-inner t-b-radius-md">
                        <img :src="`${publicPath}static/images/two/2.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                    </div>
                </parallax-object>

                <parallax-object v-bind:ratio="0.2" class="c-SectionTwo-dataThree-gradient u-w2of16 u-absolute u-pos-br u-offset-r-w1of16 | u-w4of16@sm u-offset-r-w9of16@sm">
                    <div class="c-SectionTwo-dataThree-gradient-inner t-b-radius-sm"></div>
                </parallax-object>

                <div class="c-SectionTwo-dataThree-video u-w10of16 u-offset-l-w2of16  | u-fit-w@sm u-marg-l-0@sm">
                    <video-observer class="c-SectionTwo-dataThree-video-container t-b-radius-lg">
                        <video-responsive>
                            <video class="js-video u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/two/sustainable_future-poster.jpg`" preload="auto" loop muted playsinline>
                                <source :src="`${publicPath}static/videos/two/sustainable_future.webm`" type="video/webm">
                                <source :src="`${publicPath}static/videos/two/sustainable_future.mp4`" type="video/mp4">

                                <source :srcset="`${publicPath}static/videos/two/sustainable_future-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                <source :srcset="`${publicPath}static/videos/two/sustainable_future-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                            </video>
                        </video-responsive>
                    </video-observer>
                </div>
            </div>

            <div class="u-w3of16 u-offset-l-w2of16 u-marg-t-md | u-w4of16@lg | u-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm u-relative@sm">
                <div class="u-flex">
                    <question-btn number="64" question="In the next 2-3 years do you think there will be more or less investment into innovating for green technologies?" response="[NEW SEPT 2020 BE] Base: Business executives Sept 2020: 1,128)" />
                    <share-btn class="u-marg-l-xs" />
                </div>

                <p class="c-SectionTwo-dataThree-legend t-text--sm u-opacity--50 u-italic u-marg-t-lg">How does your answer compare to the IB data? Select A or B</p>
            </div>

            <cursor-trigger v-bind:name="'click'" v-bind:active="questionActive === ''" class="u-flex u-marg-t-md u-flex-flow-row-wrap">
                <div class="c-SectionTwo-dataThree-questions u-w4of16 u-offset-l-w1of16 | u-w5of16@lg | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@md">
                    <div v-bind:class="{ 'is-active': questionActive === 'a', 'is-disabled': questionActive === 'b' }" class="c-SectionTwo-dataThree-questions-item u-flex u-align-items-c u-relative">
                        <input v-model="questionActive" class="t-input--radio u-w1of4 | u-w1of5@lg | u-w1of4@md" type="radio" id="question-a" name="questionActive" value="a">
                        <label for="question-a" class="u-w3of4 | u-w4of5@lg | u-w3of4@md u-pad-l-0@md">
                            <p class="c-SectionTwo-dataThree-questions-item-desc t-text--sm"><span v-bind:class="{ 'u-opacity--50': !questionActive || (questionActive && questionActive === 'a') }">A.</span> You feel that in the next 2-3 years, there will be more investment into innovating for green technologies.</p>
                        </label>
                    </div>
                    <div class="c-SectionTwo-dataThree-questions-line"></div>
                    <div v-bind:class="{ 'is-active': questionActive === 'b', 'is-disabled': questionActive === 'a' }" class="c-SectionTwo-dataThree-questions-item u-flex u-align-items-c u-relative">
                        <input v-model="questionActive" class="t-input--radio u-w1of4 | u-w1of5@lg | u-w1of4@md" type="radio" id="question-b" name="questionActive" value="b">
                        <label for="question-b" class="u-w3of4 | u-w4of5@lg | u-w3of4@md u-pad-l-0@md">
                            <p class="c-SectionTwo-dataThree-questions-item-desc t-text--sm"><span v-bind:class="{ 'u-opacity--50': !questionActive || (questionActive && questionActive === 'b') }">B.</span> You think that in the next 2-3 years, there will be less investment into innovating for green technologies.</p>
                        </label>
                    </div>
                </div>
                <div v-if="width >= 1024 || questionActive != -1" class="c-SectionTwo-dataThree-results u-relative u-w7of16 u-offset-l-w1of16 | u-w8of16@md u-offset-l-w5of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div v-bind:class="{ 'is-active': questionActive === 'a' }" class="c-SectionTwo-dataThree-results-item">
                        <span class="c-SectionTwo-dataThree-results-number u-color--blue">{{questionDataOne}}<sup>%</sup></span>
                        <p class="u-w3of7 t-text--sm | u-w4of7@lg | u-w10of14@md">Percentage of answer A selected by Business Executives.</p>
                        <p class="c-SectionTwo-dataThree-results-item-text t-h4">In line with the need for more sustainable innovation models, executives think there will be more investment in green technologies moving forward.</p>
                    </div>
                    <div v-bind:class="{ 'is-active': questionActive === 'b' }" class="c-SectionTwo-dataThree-results-item u-absolute u-pos-tl ">
                        <span class="c-SectionTwo-dataThree-results-number u-color--blue">{{questionDataTwo}}<sup>%</sup></span>
                        <p class="u-w3of7 t-text--sm | u-w4of7@lg | u-w10of14@md">Percentage of answer B selected by Business Executives.</p>
                        <p class="c-SectionTwo-dataThree-results-item-text t-h4">In line with the need for more sustainable innovation models, executives think there will be more investment in green technologies moving forward.</p>
                    </div>
                </div>
            </cursor-trigger>
        </section>

        <next-section v-bind:url="'/sections/redefinition-of-global-innovation'" v-bind:label="'Section 03'" v-bind:title="'A Redefinition of the Global Innovation Influence Map'" />

        <content-overlay v-bind:number="1">
            <div class="c-SectionTwo-dataFour">
                <p class="t-h4">A minority (45%) say there is an increase in the pace innovation due to the COVID-19 pandemic. Brazil and the US are experiencing a strong increase, but countries like China, France and Poland are seeing stagnation or a decrease in innovation pace.</p>
                <div class="u-marg-t-sm u-relative | u-marg-t-md@sm u-flex u-flex-flow-row-wrap | u-relative" style="z-index: 1;">
                    <question-btn number="8" question="Do you believe the COVID-19 pandemic has caused the pace of innovation in your country to increase, stay the same or decrease in the last 6 months?" response="[NEW JAN 2020 BE | ADAPTED SEPT 2020 BE] Base: Business executives Jan 2020: 2,307; Business executives Sept 2020: 1,128" />
                    <share-btn class="u-marg-l-xs" />
                </div>

                <div class="c-SectionTwo-dataFour-cols">
                    <div class="c-SectionTwo-dataFour-col">
                        <p class="t-text--sm">Top 3 countries where Business Executives believe the pace of innovation has increased in their country in the last 6 months:</p>

                        <ul class="c-SectionTwo-dataFour-lines t-list">
                            <li>
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 65%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">Brazil<span class="u-color--blue u-color--blue u-marg-x-xxs">65%</span></p>
                            </li>
                            <li>
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 61%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">USA<span class="u-color--blue u-color--blue u-marg-x-xxs">61%</span></p>
                            </li>
                            <li>
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 56%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">India<span class="u-color--blue u-color--blue u-marg-x-xxs">56%</span></p>
                            </li>
                        </ul>
                    </div>

                    <div class="c-SectionTwo-dataFour-col">
                        <p class="t-text--sm">Top 3 countries where Business Executives believe the pace of innovation has decreased in their country in the last 6 months:</p>

                        <ul class="c-SectionTwo-dataFour-lines t-list">
                            <li>
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 41%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">Poland<span class="u-color--blue u-color--blue u-marg-x-xxs">41%</span></p>
                            </li>
                            <li class="u-marg-t-xs">
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 35%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">South Africa<span class="u-color--blue u-color--blue u-marg-x-xxs">35%</span></p>
                            </li>
                            <li class="u-marg-t-xs">
                                <div class="c-SectionTwo-dataFour-lines-item">
                                    <div class="c-SectionTwo-dataFour-lines-item-blue" style="width: 33%;"></div>
                                </div>
                                <p class="t-text--xxs u-uppercase u-font-bold">Vietnam<span class="u-color--blue u-color--blue u-marg-x-xxs">33%</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </content-overlay>

    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { gsap, Quad } from 'gsap';
    import scrollToY from '@/utilities/scrollToY';
    import getOffsetTop from '@/utilities/getOffsetTop';

    import '@damienmortini/generalelectric-ib2020-globe/src/index.js';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import ObjectFit from '@/components/ObjectFit';
    import VideoObserver from '@/components/VideoObserver';
    import DefaultSlider from '@/components/DefaultSlider';
    import NextSection from '@/components/NextSection';
    import AppearObject from '@/components/AppearObject';
    import SubHeader from '@/components/SubHeader';
    import QuestionBtn from '@/components/QuestionBtn';
    import VideoResponsive from '@/components/VideoResponsive';
    import ShareBtn from '@/components/ShareBtn';
    import DiscoverBtn from '@/components/DiscoverBtn';
    import ContentOverlay from '@/components/ContentOverlay';

    export default {
        name: 'Home',

        components: {
            CursorTrigger,
            ParallaxObject,
            ObjectFit,
            VideoObserver,
            DefaultSlider,
            NextSection,
            AppearObject,
            SubHeader,
            QuestionBtn,
            VideoResponsive,
            ShareBtn,
            DiscoverBtn,
            ContentOverlay,
        },

        props: {
            initIndex: { type: Number, default: 0 },
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
                isFromSections: false,
                isFromSection: false,

                globeType: 'trade',

                hasInteractedGraph: false,
                hasInteractedGlobe: false,
                hasInteractedQuestion: false,

                graphHandlers: {
                    pointerdown: this.setInteractiveGraph,
                    touchstart: this.setInteractiveGraph,
                },

                globeHandlers: {
                    pointerdown: this.setInteractiveGlobe,
                    touchstart: this.setInteractiveGlobe,
                },

                graphActive: 0,
                questionActive: '',

                graphOneMax: 78,
                graphOne: [
                    {
                        value: 78,
                        label: 'USA',
                        cursorLabel: '+12',
                        color: 'blue',
                    },
                    {
                        value: 62,
                        label: 'Saudi Arabia',
                        cursorLabel: '+4',
                        color: 'blue',
                    },
                    {
                        value: 61,
                        label: 'India',
                        cursorLabel: '-8',
                        color: 'blue',
                    },
                    {
                        value: 59,
                        label: 'UK',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 58,
                        label: 'France',
                        cursorLabel: '-1',
                        color: 'blue',
                    },
                    {
                        value: 52,
                        label: 'Germany',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 52,
                        label: 'China',
                        cursorLabel: '-4',
                        color: 'blue',
                    },
                    {
                        value: 52,
                        label: 'Vietnam',
                        cursorLabel: '-8',
                        color: 'blue',
                    },
                    {
                        value: 47,
                        label: 'UAE',
                        cursorLabel: '-9',
                        color: 'cyan',
                    },
                    {
                        value: 46,
                        label: 'Indonesia',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 46,
                        label: 'Turkey',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 45,
                        label: 'Total',
                        cursorLabel: '',
                        color: 'gray',
                    },
                    {
                        value: 41,
                        label: 'Japan',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 38,
                        label: 'Poland',
                        cursorLabel: '-2',
                        color: 'cyan',
                    },
                    {
                        value: 38,
                        label: 'South Korea',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 37,
                        label: 'Malaysia',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 36,
                        label: 'Mexico',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 30,
                        label: 'South Africa',
                        cursorLabel: '-8',
                        color: 'cyan',
                    },
                    {
                        value: 29,
                        label: 'Brazil',
                        cursorLabel: '-16',
                        color: 'cyan',
                    },
                    {
                        value: 28,
                        label: 'Israel',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 27,
                        label: 'Iraq',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 20,
                        label: 'Nigeria',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 10,
                        label: 'Kenya',
                        cursorLabel: '',
                        color: 'cyan',
                    }
                ],
                graphTwoMax: 85,
                graphTwo: [
                    {
                        value: 85,
                        label: 'China',
                        cursorLabel: '39%',
                        color: 'blue',
                    },
                    {
                        value: 78,
                        label: 'Kenya',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 77,
                        label: 'Indonesia',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 77,
                        label: 'Vietnam',
                        cursorLabel: '40%',
                        color: 'blue',
                    },
                    {
                        value: 71,
                        label: 'USA',
                        cursorLabel: '61%',
                        color: 'blue',
                    },
                    {
                        value: 70,
                        label: 'India',
                        cursorLabel: '56%',
                        color: 'blue',
                    },
                    {
                        value: 68,
                        label: 'UAE',
                        cursorLabel: '44%',
                        color: 'blue',
                    },
                    {
                        value: 66,
                        label: 'Nigeria',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 64,
                        label: 'Malaysia',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 63,
                        label: 'Saudi Arabia',
                        cursorLabel: '50%',
                        color: 'blue',
                    },
                    {
                        value: 61,
                        label: 'Israel',
                        cursorLabel: '',
                        color: 'blue',
                    },
                    {
                        value: 59,
                        label: 'Brazil',
                        cursorLabel: '65%',
                        color: 'blue',
                    },
                    {
                        value: 56,
                        label: 'Total',
                        cursorLabel: '45%',
                        color: 'gray',
                    },
                    {
                        value: 49,
                        label: 'South Africa',
                        cursorLabel: '46%',
                        color: 'cyan',
                    },
                    {
                        value: 49,
                        label: 'Turkey',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 45,
                        label: 'Germany',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 43,
                        label: 'South Korea',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 43,
                        label: 'UK',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 40,
                        label: 'Mexico',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 36,
                        label: 'Poland',
                        cursorLabel: '24%',
                        color: 'cyan',
                    },
                    {
                        value: 30,
                        label: 'France',
                        cursorLabel: '29%',
                        color: 'cyan',
                    },
                    {
                        value: 29,
                        label: 'Japan',
                        cursorLabel: '',
                        color: 'cyan',
                    },
                    {
                        value: 19,
                        label: 'Iraq',
                        cursorLabel: '',
                        color: 'cyan',
                    }
                ],

                questionDataOne: 62,
                questionDataTwo: 0,
            };
        },

        computed: {
            ...mapState('window', [
                'width',
                'minHeight',
                'isFF',
                'isTouch',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'contentOverlayOpen',
            ]),

            ...mapGetters('window', [
                'isMobile',
                'halfHeight'
            ]),
        },

        watch: {
            graphActive: 'onGraphChange',
            questionActive: 'onQuestionChange',
        },

        beforeRouteEnter (to, from, next) {
            next((vm) => {
                if(from.meta.type === 'section') {
                    vm.isFromSection = true;
                }

                if (from.name === 'Sections') {
                    vm.isFromSections = true;
                }
            });
        },

        mounted() {
            this.setCurrentNav(20);
            this.$eventHub.$on('page:ready', this.onPageReady);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('route:scroll', this.onRouteScroll);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');

                this.$navSections = Array.from(this.$el.querySelectorAll('.js-nav-section'));
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('route:scroll', this.onRouteScroll);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav',
            ]),

            resize() {
                if (!this.$navSections) return;

                this.cuePoints = [];

                this.$navSections.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.halfHeight && this.$root.smoothScroll < nextCuePoints - this.halfHeight ) {
                        this.setCurrentNav(20+i);
                    }
                });
            },

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;

                if (this.initIndex) {
                    this.cuePoints[this.initIndex];

                    const offset = this.initIndex < 2 ? 0 : 160;
                    scrollToY(this.cuePoints[this.initIndex] - offset);
                }

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);
            },

            onRouteScroll(index) {
                if (!this.isPageReady) return;

                const offset = index < 2 ? 0 : 160;
                scrollToY(this.cuePoints[index] - offset);
            },

            onGraphChange() {
                if (this.graphActive === 0) {
                    this.$refs.sliderTwo.goToItem(0);
                } else {
                    this.$refs.sliderOne.goToItem(0);
                }
            },

            onQuestionChange() {
                let init  = { value: (this.questionActive === 'a') ? 42 : 12 };
                let target = (this.questionActive === 'a') ? 62 : 32;

                gsap.to(init, 0.8, { value: target, ease: Quad.easeOut, onUpdate: () => {
                    if (this.questionActive === 'a') {
                        this.questionDataOne = Math.round(init.value);
                    } else {
                        this.questionDataTwo = Math.round(init.value);
                    }
                } });
            },

            onGlobeChange(type) {
                this.globeType = type;

                this.$refs.globe.mode = type;
            },

            setInteractiveGraph() {
                this.hasInteractedGraph = true;
            },

            setInteractiveGlobe() {
                this.hasInteractedGlobe = true;
            }
        },
    }
</script>

<style lang="stylus">
    .c-SectionTwo {
        &-blur {
            transition filter 0.5s $ease-out-quart 0.4s, background 0.5s $ease-out-quart 0.4s, opacity 0.5s $ease-out-quart 0.4s
            transform translate3d(0, 0, 0)
            opacity 1

            &.is-blurred {
                filter blur(60px)
                background #fafcfe

                @media $breakpoints.sm {
                    filter unset
                }
            }

            &.is-hidden {
                background #cacaca
                opacity 0.1
            }
        }

        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            .is-page-ready & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..4 {
                &--{n} {
                     transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)
                }
            }

            .is-from-sections & {
                opacity 1
                transform translateZ(0)
                transition none;
            }
        }

        &-intro {
            // z-index 0

            &-video {
                opacity 0
                transform translateZ(0)
                transition opacity 0.8s $ease-out-quart
                will-change opacity

                .is-page-ready & {
                    opacity 1
                }

                .is-from-section & {
                    opacity 1
                    transition none
                }
            }

            &-title {
                &-line {
                    &-2 {
                        margin-top 145px

                        @media $breakpoints.lg {
                            margin-top calc(145 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            margin-top calc(75 * 100vh / 1125)
                        }
                    }

                    &-3 {
                        margin-top 5px

                        @media $breakpoints.sm {
                            margin-top 0
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 75px
                    margin-bottom 95px
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }
        }

        &-dataOne {
            // z-index 1
            padding-top 355px
            padding-bottom 305px

            @media $breakpoints.sm {
                padding-top 270px
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 155px

                @media $breakpoints.sm {
                    padding-bottom 115px
                }
            }

            &-img {
                margin-bottom 70px
                padding-left 2.77%
                padding-right 1.39%

                @media $breakpoints.sm {
                    top 0
                    bottom auto
                    margin-bottom 0
                    padding-left 0
                    padding-right 0
                }

                &-inner {
                    aspect-ratio(446, 288)
                }
            }

            &-webgl {
                padding-left 2.77%

                @media $breakpoints.sm {
                    padding-top 115px
                    padding-left 0
                }

                &-inner {
                    aspect-ratio(808, 504)
                    overflow hidden

                    backdrop-filter: blur(7px);
                    background linear-gradient(to bottom, #0060b4, rgba(#0eb5df, 0.1));

                    @media $breakpoints.md {
                        aspect-ratio(1, 1.2)
                    }

                    @media $breakpoints.sm {
                        aspect-ratio(678, 886)
                    }

                    cursor grab

                    &:active {
                        cursor grabbing
                    }
                }
            }

            &-switch {
                margin-top 60px

                @media $breakpoints.sm {
                    margin-top 45px
                }

                &-item {
                    opacity 0
                    visibility hidden
                    transform translateZ(0)
                    will-change opacity, visibility

                    transition none

                    &.is-active {
                        opacity 1
                        visibility visible

                        transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                    }
                }

                &-text {
                    margin-top 20px

                    @media $breakpoints.sm {
                        margin-top 70px
                    }

                    &-p {
                        opacity 0
                        visibility hidden
                        transform translateZ(0)
                        will-change opacity, visibility

                        transition none

                        &.is-active {
                            opacity 1
                            visibility visible

                            transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                        }
                    }
                }
            }
        }

        &-dataTwo {
            // z-index 1
            padding-bottom 200px

            @media $breakpoints.sm {
                padding-bottom 150px
            }

            &-icon {
                margin-right 10px
            }

            &-desc {
                padding-top 145px
                padding-bottom 110px

                &--alt {
                    padding-top 100px

                    @media $breakpoints.sm {
                        padding-top 90px
                    }
                }

                &-paragraph {
                    margin-top 55px
                    width 50%

                    @media $breakpoints.sm {
                        margin-top 50px
                        width 100%
                    }
                }
            }

            &-graphs {
                margin-top 5px
                margin-bottom 80px

                @media $breakpoints.sm {
                    margin-bottom 70px
                }

                &-desc {
                    opacity 0
                    visibility hidden
                    transform translateZ(0)
                    will-change opacity, visibility

                    transition none

                    &.is-active {
                        opacity 1
                        visibility visible

                        transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                    }
                }
            }

            &-graph {
                opacity 0
                visibility hidden
                transform translateZ(0)
                will-change opacity, visibility

                transition none

                &.is-active {
                    opacity 1
                    visibility visible

                    transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                }

                &-label {
                    position relative
                    margin-top 40px
                    letter-spacing 0.05em
                }
            }

            &-bar {
                transform translateZ(0)
                will-change transform
                overflow hidden
                position relative
                padding 0 3px
                min-width 80px
                overflow visible
                // perspective 40px

                @media $breakpoints.sm {
                    min-width 75px
                }

                for n in (1..13) {
                    &:nth-child({n}) {
                        .c-SectionTwo-dataTwo-bar-color-bg {
                            .is-active & {
                                .is-appear & {
                                    transition transform 0.8s $ease-out-quart (n * 0.05s)
                                }
                            }
                        }
                    }
                }

                &-inner {
                    height 400px
                    width 100%
                    position relative
                    display inline-block
                    vertical-align bottom
                }

                &-color {
                    position absolute
                    bottom 0
                    left 0
                    width 100%
                    height 100%

                    &-bg {
                        border-radius 4px
                        box-shadow: inset 0px 1px 0px 1px rgba(white, 0.5)
                        transform-origin bottom
                        transform scaleY(0) translateZ(0)
                        transition transform 0s $ease-out-quart 0.4s
                        will-change transform

                        .is-active & {
                            .is-appear & {
                                transform scaleY(1) translateZ(0)
                            }
                        }

                        &.is-blue {
                            background linear-gradient(to bottom, #0060b4, #0ea2df, #FFFFFF);
                        }

                        &.is-dark {
                            background linear-gradient(to bottom, #12375b, #0060b4, #FFFFFF);
                        }

                        &.is-cyan {
                            background linear-gradient(to bottom, #0eb5df, #86daef, #FFFFFF);
                        }

                        &.is-gray {
                            background linear-gradient(to bottom, #c0c0c0, #FFFFFF);
                        }
                    }

                    .u-pad-a-sm {
                        transform translateZ(0)
                        will-change transform
                    }

                    .t-text--xxs {
                        letter-spacing 0.05em
                    }
                }

                &-diff {
                    width 42px
                    height 42px

                    border-radius 50%
                    box-shadow 0px 10px 24px -11px rgba(0, 0, 0, 0.75)

                    left 50%
                    margin-left -21px

                    @media $breakpoints.sm {
                        box-shadow none

                        &.has-drop-shadow {
                            box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.4);
                        }
                    }
                }
            }

            &-legend {
                position absolute
                top 6px
                left -18px
                width 10px
                height 8px
                border-radius 2px
                display inline-block
                vertical-align middle
            }

            &-legend + &-legend {
                left -32px
            }
        }

        &-dataThree {
            // z-index 1
            padding-bottom 310px

            @media $breakpoints.sm {
                padding-bottom 270px
            }

            &-desc {
                padding-top 145px
                padding-bottom 115px
            }

            &-two-col {
                padding-top 45px
                padding-bottom 160px

                > div:nth-of-type(2) {
                    left: 3.125%
                }

                @media $breakpoints.sm {
                    > div:nth-of-type(2) {
                        left: unset
                    }
                }
            }

            &-legend {
                margin-top 60px
            }

            &-gradient {
                bottom 270px
                width 9.028%
                backdrop-filter blur(7px)

                @media $breakpoints.lg {
                    top -20px
                    bottom auto
                }

                @media $breakpoints.md {
                    padding-left 0
                    bottom auto
                    top -30px
                }

                @media $breakpoints.sm {
                    padding-left 0
                    bottom auto
                    top 0
                    width 25%
                }

                &-inner {
                    aspect-ratio(120, 150)
                    overflow hidden

                    background linear-gradient(to top, #0060b4, rgba(#0eb5df, 0.1));
                }
            }

            &-img {
                bottom -45px
                padding 0 2.78%

                @media $breakpoints.sm {
                    padding 0
                    padding-top 30px
                    bottom auto
                    top 0
                }

                &-inner {
                    aspect-ratio(340, 428)
                    overflow hidden
                }
            }

            &-video {
                padding-right 2.78%

                @media $breakpoints.sm {
                    padding-top 255px
                    padding-right 0
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    // background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }

            &-questions {
                &-line {
                    position relative
                    left -5%
                    width 110%
                    height 1px
                    background $colors['gray'];

                    // @media $breakpoints.lg {
                    //     left -5%
                    //     width 110%
                    // }

                    @media $breakpoints.sm {
                        left -10%
                        width 120%
                    }
                }

                &-item {
                    margin 30px 0

                    &:last-child {
                        position relative

                        @media $breakpoints.md {
                            padding-bottom 0px
                        }
                    }

                    &:hover {
                        .c-SectionTwo-dataThree-questions-item-letter {
                            opacity 1

                            &:before {
                                opacity 1
                            }
                        }
                    }

                    &-letter {
                        display inline-block

                        opacity 0.3
                        transform translateZ(0)
                        transition color 0.6s $ease-out-quart, opacity 0.6s $ease-out-quart
                        will-change color, opacity

                        .is-active & {
                            opacity 1
                        }

                        &:before {
                            content ""
                            position absolute
                            top 50%
                            right 0px

                            width 6px
                            height 6px

                            margin-top -2px

                            border-radius 50px

                            background $colors['black'];

                            opacity 0
                            transform translateZ(0)
                            transition opacity 0.6s $ease-out-quart
                            will-change opacity
                        }

                        &:after {
                            content ""
                            width 6px
                            height 6px
                            display inline-block
                            vertical-align middle
                            border-radius 50px
                            margin-left 20px
                            background $colors['blue'];

                            opacity 0
                            transform translateZ(0)
                            transition opacity 0.6s $ease-out-quart
                            will-change opacity
                        }


                        .is-active & {
                            opacity 1
                            color  $colors['blue'];

                            &:after {
                                opacity 1
                            }
                        }
                    }

                    &-desc {
                        opacity 1
                        transform translateZ(0)
                        transition opacity 0.6s $ease-out-quart
                        will-change opacity

                        .is-disabled & {
                            opacity 0.5
                        }
                    }
                }
            }

            &-results {
                // padding 0 2.78%

                @media $breakpoints.md {
                    margin-top 95px
                }

                &-item {
                    opacity 0
                    visibility hidden
                    transform translateZ(0)
                    will-change opacity, visibility

                    transition none

                    &.is-active {
                        opacity 1
                        visibility visible

                        transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
                    }

                    &-text {
                        margin-top 55px

                        @media $breakpoints.sm {
                            margin-top 75px
                        }
                    }
                }

                &-number {
                    line-height 1.05
                    font-size 11.528vw

                    @media $breakpoints.sm {
                        font-size 40.8vw
                    }

                    sup {
                        top -0.5em
                        font-size 60%
                    }
                }
            }
        }

        &-dataFour {
            &-cols {
                display flex
                justify-content space-between
                margin-top 85px

                @media $breakpoints.sm {
                    display block
                    margin-top 120px
                }
            }

            &-col {
                width calc(50% - 15px)

                @media $breakpoints.sm {
                    width 100%

                    &:nth-child(2) {
                        margin-top 80px
                    }
                }
            }

            &-lines {
                margin-top 45px

                @media $breakpoints.sm {
                    margin-top 50px
                }

                li + li {
                    margin-top 10px
                }

                .t-text--xxs {
                    letter-spacing 0.05em
                }

                &-item {
                    overflow hidden
                    transform translateZ(0)
                    position relative
                    height 4px
                    width 100%
                    background $colors['gray'];
                    margin-bottom 8px

                    &-blue, &-cyan {
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%

                        will-change transform
                        transform-origin left
                        transform scaleX(0) translateZ(0)
                        transition transform 0.8s $ease-out-quart

                        .is-appear & {
                            transform scaleX(1) translateZ(0)
                        }
                    }

                    &-blue {
                        background $colors['blue'];
                    }

                    &-cyan {
                        background $colors['blue-light'];
                    }
                }
            }
        }

    }
</style>